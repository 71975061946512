import { v4 } from 'uuid';
import type { FeedEntry, UIComponent } from '~/services/layout/index.server.ts';
import type {
  AnalyticsPlayerEventNames,
  UserActionsEventNames,
  AnalyticsScreenEventNames,
  AnalyticsTapEventNames,
  AnalyticsPurchaseEventNames,
  AnalyticsSearchEventNames,
} from '~/services/analytics/events-names.ts';
import logger from '~/services/logger';

export const GoogleAnalyticsManager = ({
  gaTrackingId,
  analyticsSharedVars,
}: any): JSX.Element | null => {
  return gaTrackingId ? (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
      />
      <script
        async
        id="gtag-init"
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${gaTrackingId}', ${JSON.stringify(
            analyticsSharedVars
          )});
            `,
        }}
      />
    </>
  ) : null;
};

export const GoogleTagManager = ({
  gtmTrackingId,
}: any): JSX.Element | null => {
  return gtmTrackingId ? (
    <script
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gtmTrackingId}');`,
      }}
    />
  ) : null;
};

export const GoogleTagManagerNoScript = ({
  gtmTrackingId,
}: any): JSX.Element | null => {
  return gtmTrackingId ? (
    <noscript>
      <iframe
        title="googletagmanager"
        src={`https://www.googletagmanager.com/ns.html?id=${gtmTrackingId}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>
    </noscript>
  ) : null;
};

export function getZappClientUUID() {
  try {
    const uuidKey = 'zapp-uuid';

    const currentUuid = localStorage.getItem(uuidKey);

    const uuid = currentUuid || v4();

    if (!currentUuid) localStorage.setItem(uuidKey, JSON.stringify(uuid));

    return uuid;
  } catch (error) {
    return v4();
  }
}

export type EventName =
  | AnalyticsPlayerEventNames
  | UserActionsEventNames
  | AnalyticsScreenEventNames
  | AnalyticsTapEventNames
  | AnalyticsPurchaseEventNames
  | AnalyticsSearchEventNames;

export type AnalyticsData = {
  eventName: EventName;
  trackData: unknown;
};

export const getTapCellAnalyticsData = (
  eventName: EventName,
  analyticsTrackData: any,
  UIComponent?: UIComponent,
  entry?: FeedEntry
) => {
  const {
    id: component_id,
    component_type,
    name: header_name,
    styles,
  } = UIComponent || {};

  const { id: entry_id, type, title, _entryIndex }: any = entry || {};

  return {
    eventName,
    trackData: {
      ...analyticsTrackData,
      component_type,
      component_id,
      header_name,
      cell_style: styles?.cell_plugin_configuration_id,
      item_type: type?.value,
      item_number: _entryIndex,
      item_name: title,
      item_id: entry_id,
    },
  };
};

export const getNavItemAnalyticsData = (
  eventName: EventName,
  analyticsTrackData: any,
  additionalData?: any
) => {
  try {
    const {
      version,
      build_number,
      bundle_id,
      sdk_version,
      layout_id,
      zapp_platform,
      analyticsCustomProperties,
    } = analyticsTrackData;

    return {
      eventName,
      trackData: {
        version,
        build_number,
        bundle_id,
        sdk_version,
        layout_id,
        zapp_platform,
        analyticsCustomProperties,
        ...additionalData,
      },
    };
  } catch (error: any) {
    logger.info(`getNavItemAnalyticsData: ${error.message}`);
    return {
      eventName,
      trackData: {},
    };
  }
};

export const getPurchaseAnalyticData = (
  eventName: EventName,
  analyticsTrackData: any,
  additionalData?: any
) => {
  try {
    const {
      offer,
      currency,
      totalPrice,
      totalAmount,
      offerId,
      offerV2: {
        type: baseType,
        price: {
          currency: baseCurrency,
          longId: baseLongId,
          amount: baseAmount,
        },
        title: baseTitle,
      },
      id,
      offerType,
      paymentOperation,
      screenName,
    } = additionalData || {};

    const generalTrackData = {
      ...analyticsTrackData,
      currency_code: currency,
      purchase_id: offerId,
      screen_name: screenName,
      transaction_id: id,
    };

    const createTrackData = (overrides: object) => ({
      eventName,
      trackData: {
        ...generalTrackData,
        ...overrides,
      },
    });

    switch (eventName) {
      case 'purchase_triggered':
      case 'purchase_cancelled':
        return createTrackData({
          price_numeric: totalPrice,
          purchase_price: `${totalPrice} ${currency}`,
          purchase_type: baseType,
          purchase_name: offer?.title,
        });

      case 'purchase':
        return {
          eventName,
          trackData: {
            currency,
            value: totalAmount,
            transaction_id: id,
            items: [
              {
                item_id: offerId,
                item_name: paymentOperation,
              },
            ],
          },
        };

      case 'purchase_success':
        return createTrackData({
          price_numeric: totalAmount,
          purchase_price: `${totalAmount} ${currency}`,
          purchase_type: offerType,
          purchase_name: paymentOperation,
        });

      default:
        return createTrackData({
          currency_code: baseCurrency,
          purchase_id: baseLongId,
          price_numeric: baseAmount,
          purchase_price: `${baseAmount} ${baseCurrency}`,
          purchase_type: baseType,
          purchase_name: baseTitle,
        });
    }
  } catch (error: any) {
    logger.info(`getPurchaseAnalyticData: ${error.message}`);
    return {
      eventName,
      trackData: {},
    };
  }
};
